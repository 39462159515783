import {LitElement, html} from 'lit';
import SimpleLightbox from "simplelightbox";
import {createRef, ref} from 'lit/directives/ref.js';

export class CoverImage extends LitElement {
  static get properties() {
    return {
      src: {type: String},
      thumbnailSrc: {type: String},
      alt: {type: String},
      cropped: {type: Boolean},
      offsetX: {type: Number},
      offsetY: {type: Number},
      scale: {type: Number},
      cropAreaHeight: {type: Number},
      cropAreaWidth: {type: Number},
    }
  }

  lightboxRef = createRef();
  coverRef = createRef();
  lightbox = null;

  firstUpdated() {
    if (this.src) {
      this.lightbox = new SimpleLightbox(
        [this.lightboxRef.value],
        {
          fileExt: false,
          scrollZoomFactor: 0.05,
          overlayOpacity: 0.9,
          widthRatio: 1,
        }
      )
      if (this.cropAreaWidth) {
        const img = new Image()
        img.src = this.thumbnailSrc
        img.onload = () => {
          this.applyCrop(img);
        }
      }
    }
  }

  createRenderRoot() {
    return this;
  }

  render() {
    if (this.src) {
      return html`
        <link rel="preload" as="image" href="${this.src}">
        <a href="${this.src}" ${ref(this.lightboxRef)} alt="${this.alt}">
          <div class="cover" ${ref(this.coverRef)} style="background-image: url('${this.thumbnailSrc}')"></div>
        </a>
      `
    }
    return html`
      <div class="event event--cover">
        <div class="event__placeholder" style="background-image: url('${window.placeholderImage}')"></div>
      </div>
    `;
  }

  applyCrop(img) {
    const [scaledX, scaledY] = this.getRelativePixelSize(
      this.coverRef.value.clientWidth,
      this.cropAreaWidth,
      this.offsetX,
      this.offsetY
    )

    const [scaledWidth, scaledHeight] = this.getImageScale(
      img,
      this.scale,
      this.cropAreaWidth,
      this.cropAreaHeight,
      this.coverRef.value.clientWidth,
      this.coverRef.value.clientHeight
    )

    this.coverRef.value.style.backgroundPosition = `${scaledX}px ${scaledY}px`;
    this.coverRef.value.style.backgroundSize = `${scaledWidth}px ${scaledHeight}px`;
  }

  getRelativePixelSize(clientWidth, cropWidth, offsetX, offsetY) {
    const pixelScale = clientWidth / cropWidth;
    return [offsetX * pixelScale, offsetY * pixelScale]
  }

  getImageScale(img, scale, cropWidth, cropHeight, clientWidth, clientHeight) {
    const imageAspectRatio = img.naturalWidth / img.naturalHeight;
    const frameAspectRatio = cropWidth / cropHeight;
    let baseScale;
    if (imageAspectRatio > frameAspectRatio) {
      baseScale = clientWidth / img.naturalWidth;
    } else {
      baseScale = clientHeight / img.naturalHeight;
    }

    const relativeScale = baseScale * this.scale;

    return [img.naturalWidth * relativeScale, img.naturalHeight * relativeScale]
  }
}

customElements.define('cover-image', CoverImage);
